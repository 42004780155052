import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Mypage from '../views/Mypage.vue'
import Login from '../views/Login.vue'
import Setting from '../views/Setting.vue'
import TermsOfService from '../views/TermsOfService.vue'

import { Hub } from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";

let user;

function getUser() {
  return Auth.currentAuthenticatedUser()
    .then((data) => {
      if (data && data.signInUserSession) {
        return data;
      }
    })
    .catch((e) => {
      console.error(e);
      return null;
    });

}

Hub.listen("auth", async (data) => {
  switch (data.payload.event) {
    case "signIn":
      user = await getUser();
      router.push({ path: "/mypage" });
      break;
    case "signOut":
      user = null;
      router.push({ path: "/login" });
      break;
  }
});

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/mypage',
    name: 'Mypage',
    component: Mypage,
    meta: { requiresAuth: true }
  },
  {
    path: '/setting',
    name: 'Setting',
    component: Setting,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/terms_of_service',
    name: 'TermsOfService',
    component: TermsOfService,
  },


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeResolve(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    user = await getUser();
    // console.log(user);
    if (!user) {
      return next({
        path: "/login",
        query: {
          redirect: to.fullPath,
        },
      });
    }
    return next();
  }
  return next();
});

export default router
