
<template>
  <div>
    <div class="container px-0 text-left">
        <div class="row px-0 py-2">
            <div class="col-4 text-left">
               <select v-model="currentMonth" class="form-select form-select-sm small-select" aria-label="" @change="onChangeMonth()">
                 <option v-for="d in daySelect" :key="d.value" :value="d.value"> {{ d.label }}月</option> 
               </select>
            </div>
            <div class="col-8">
            </div>
        </div>
        <small class="">過去3ヶ月分が見れるようになりました</small><br/>
        <small class="">イベラスの反映が3月5日になります</small>
    </div>
    <div class="calendar">
      <div class="ofWeek_raw">
        <div class="dayOfWeek"> <p>月</p> </div>
        <div class="dayOfWeek"> <p>火</p> </div>
        <div class="dayOfWeek"> <p>水</p> </div>
        <div class="dayOfWeek"> <p>木</p> </div>
        <div class="dayOfWeek"> <p>金</p> </div>
        <div class="dayOfWeek"> <p>土</p> </div>
        <div class="dayOfWeek"> <p>日</p> </div>
      </div>
      <div
        v-for="(week, index) in calendars"
        :key="index"
        class="week_raw"
      >
        <div v-for="(day, k) in week" :key="k" class="day">
          <span v-if="isThisMonth(day.date, index)">{{ day.date }}</span>
          <span class="gray_out" v-else>{{ day.date }}</span>

          <p v-if="isThisMonth(day.date, index)" class="meterborder" v-html="getMeterBorder(day.date, index)">
          </p>
          <p v-else></p>
        </div>
      </div>
    </div>
  </div>


</template>
<script>

import axios from "axios";
import moment from "moment";

export default {
  data() {
    return {
      idToken: localStorage.getItem("idToken"),
      currentDate: moment(),
      currentMonth: "0",
      days: [],
      daySelect: [],

      eventLast: {
          "0901": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "0902": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 9, "D": 9,
          },
          "0903": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 2, "D": 2,
          },
          "0904": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "0905": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 16, "C": 19, "D": 1,
          },
          "0906": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 1, "D": 1,
          },
          "0907": {
              "S6": 19, "S5": 19, "S4": 19,
              "S3": 22,"S2": 22, "S1": 22,
              "A": 23, "B": 6, "C": 4, "D": 2,
          },
          "0908": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 11, "D": 9,
          },
          "0909": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 2,
          },
          "0910": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 1, "D": 1,
          },
          "0911": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 3, "B": 3, "C": 2, "D": 2,
          },
          "0912": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 19, "C": 21, "D": 1,
          },
          "0913": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 3, "B": 3, "C": 2, "D": 2,
          },
          "0914": {
              "S6": 20, "S5": 20, "S4": 20,
              "S3": 23,"S2": 23, "S1": 23,
              "A": 24, "B": 6, "C": 4, "D": 2,
          },
          "0915": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 12, "D": 11,
          },
          "0916": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 1, "C": 1, "D": 1,
          },
          "0917": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 0, "B": 1, "C": 2, "D": 2,
          },
          "0918": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 3, "D": 3,
          },
          "0919": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 20, "C": 22, "D": 1,
          },
          "0920": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 2, "D": 1,
          },
          "0921": {
              "S6": 21, "S5": 21, "S4": 21,
              "S3": 24,"S2": 24, "S1": 24,
              "A": 25, "B": 6, "C": 4, "D": 2,
          },
          "0922": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 12, "D": 10,
          },
          "0923": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 2, "C": 2, "D": 2,
          },
          "0924": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 1, "D": 1,
          },
          "0925": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 3, "D": 3,
          },
          "0926": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 21, "C": 23, "D": 1,
          },
          "0927": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 3, "D": 2,
          },
          "0928": {
              "S6": 23, "S5": 23, "S4": 23,
              "S3": 25,"S2": 25, "S1": 25,
              "A": 26, "B": 6, "C": 4, "D": 2,
          },
          "0929": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 13, "D": 12,
          },
          "0930": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 2, "C": 1, "D": 1,
          },

          "1001": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "1002": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "1003": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "1004": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 2,
          },
          "1005": {
              "S6": 20, "S5": 20, "S4": 20,
              "S3": 22,"S2": 22, "S1": 22,
              "A": 23, "B": 3, "C": 1, "D": 1,
          },
          "1006": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 1, "C": 3, "D": 1,
          },
          "1007": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 3, "D": 2,
          },
          "1008": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 5, "C": 3, "D": 1,
          },
          "1009": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 4, "B": 4, "C": 2, "D": 3,
          },
          "1010": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 21, "C": 24, "D": 1,
          },
          "1011": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 2, "D": 1,
          },
          "1012": {
              "S6": 22, "S5": 22, "S4": 22,
              "S3": 24,"S2": 24, "S1": 24,
              "A": 25, "B": 5, "C": 1, "D": 1,
          },
          "1013": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 13, "D": 12,
          },
          "1014": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 2,
          },
          "1015": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 3, "D": 2,
          },
          "1016": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 3, "D": 2,
          },
          "1017": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 20, "C": 23, "D": 1,
          },
          "1018": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 1, "D": 1,
          },
          "1019": {
              "S6": 23, "S5": 23, "S4": 23,
              "S3": 25,"S2": 25, "S1": 25,
              "A": 26, "B": 6, "C": 1, "D": 1,
          },
          "1020": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 13, "D": 11,
          },
          "1021": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 3, "D": 3,
          },
          "1022": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 4, "D": 1,
          },
          "1023": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 3, "D": 2,
          },
          "1024": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 20, "C": 23, "D": 1,
          },
          "1025": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 1, "D": 1,
          },
          "1026": {
              "S6": 22, "S5": 22, "S4": 22,
              "S3": 24,"S2": 24, "S1": 24,
              "A": 25, "B": 5, "C": 1, "D": 2,
          },
          "1027": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 14, "D": 12,
          },
          "1028": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 2,
          },
          "1029": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 2, "D": 1,
          },
          "1030": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 3, "D": 2,
          },
          "1031": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 20, "C": 22, "D": 1,
          },


          "1101": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "1102": {
              "S6": 20, "S5": 20, "S4": 20,
              "S3": 22,"S2": 22, "S1": 22,
              "A": 22, "B": 2, "C": 0, "D": 0,
          },
          "1103": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 3, "D": 2,
          },
          "1104": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 2,
          },
          "1105": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 1, "D": 1,
          },
          "1106": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 3, "D": 2,
          },
          "1107": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 23, "C": 24, "D": 2,
          },
          "1108": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 3, "B": 3, "C": 1, "D": 1,
          },
          "1109": {
              "S6": 24, "S5": 24, "S4": 24,
              "S3": 26,"S2": 26, "S1": 26,
              "A": 26, "B": 6, "C": 2, "D": 1,
          },
          "1110": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 25, "D": 15,
          },
          "1111": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 2,
          },
          "1112": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 4, "C": 2, "D": 2,
          },
          "1113": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3, 
              "A": 3, "B": 3, "C": 3, "D": 2,
          },
          "1114": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 22, "C": 24, "D": 2,
          },
          "1115": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 3, "D": 3,
          },
          "1116": {
              "S6": 23, "S5": 23, "S4": 23,
              "S3": 25,"S2": 25, "S1": 25,
              "A": 25, "B": 5, "C": 2, "D": 1,
          },
          "1117": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 13, "D": 12,
          },
          "1118": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 2,
          },
          "1119": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 1, "D": 1,
          },
          "1120": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 3, "D": 2,
          },
          "1121": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 22, "C": 24, "D": 2,
          },
          "1122": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 1, "D": 1,
          },
          "1123": {
              "S6": 24, "S5": 24, "S4": 24,
              "S3": 27,"S2": 27, "S1": 27,
              "A": 27, "B": 7, "C": 3, "D": 1,
          },
          "1124": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 14, "D": 14,
          },
          "1125": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 2,
          },
          "1126": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 2,
          },
          "1127": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 3, "D": 2,
          },
          "1128": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 21, "C": 23, "D": 2,
          },
          "1129": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 1, "D": 1,
          },
          "1130": {
              "S6": 24, "S5": 24, "S4": 24,
              "S3": 26,"S2": 26, "S1": 26,
              "A": 26, "B": 6, "C": 2, "D": 1,
          },


          "1201": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "1202": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "1203": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 2,
          },
          "1204": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 3, "D": 3,
          },
          "1205": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 2, "D": 1,
          },
          "1206": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 2,
          },
          "1207": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 2, "D": 1,
          },
          "1208": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 2, "C": 12, "D": 14,
          },
          "1209": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 6,"S2": 6, "S1": 6,
              "A": 6, "B": 4, "C": 4, "D": 2,
          },
          "1210": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 6,"S2": 6, "S1": 6,
              "A": 6, "B": 4, "C": 0, "D": 0,
          },
          "1211": {
              "S6": 7, "S5": 7, "S4": 7,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 6, "C": 6, "D": 2,
          },
          "1212": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 5, "B": 5, "C": 4, "D": 1,
          },
          "1213": {
              "S6": 6, "S5": 6, "S4": 6,
              "S3": 6,"S2": 6, "S1": 6,
              "A": 6, "B": 6, "C": 4, "D": 2,
          },
          "1214": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 6,"S2": 6, "S1": 6,
              "A": 6, "B": 6, "C": 5, "D": 1,
          },
          "1215": {
              "S6": 2, "S5": 2, "S4": 2, 
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 4, "C": 6, "D": 3,
          },
          "1216": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 4, "C": 6, "D": 2,
          },
          "1217": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 3, "B": 4, "C": 4, "D": 3,
          },
          "1218": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 6,"S2": 6, "S1": 6,
              "A": 6, "B": 6, "C": 10, "D": 9,
          },
          "1219": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 5, "C": 6, "D": 3,
          },
          "1220": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 4, "B": 4, "C": 8, "D": 6,
          },
          "1221": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 6, "C": 8, "D": 4,
          },
          "1222": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 6, "C": 9, "D": 5,
          },
          "1223": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 7, "D": 6,
          },
          "1224": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 4, "B": 5, "C": 5, "D": 3,
          },
          "1225": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 2, "D": 2,
          },
          "1226": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 1,
          },
          "1227": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 2, "D": 2,
          },
          "1228": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 2, "D": 1,
          },
          "1229": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "1230": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "1231": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },


          "0101": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "0102": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "0103": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 1, "D": 1,
          },
          "0104": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "0105": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "0106": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "0107": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "0108": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 1, "D": 1,
          },
          "0109": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "0110": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 2,
          },
          "0111": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 1, "C": 2, "D": 1,
          },
          "0112": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 1, "C": 1, "D": 2,
          },
          "0113": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 0, "C": 0, "D": 0,
          },
          "0114": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 3, "C": 2, "D": 2,
          },
          "0115": {
              "S6": 3, "S5": 3, "S4": 3, 
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 5, "D": 3,
          },
          "0116": {
              "S6": 6, "S5": 6, "S4": 6,
              "S3": 7,"S2": 7, "S1": 7,
              "A": 7, "B": 7, "C": 3, "D": 1,
          },
          "0117": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 6,"S2": 6, "S1": 6,
              "A": 6, "B": 7, "C": 7, "D": 2,
          },
          "0118": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 5, "C": 5, "D": 1,
          },
          "0119": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 3, "C": 4, "D": 3,
          },
          "0120": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 3, "D": 2,
          },
          "0121": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 4, "D": 3,
          },
          "0122": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 3, "C": 5, "D": 4,
          },
          "0123": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 5, "B": 6, "C": 5, "D": 4,
          },
          "0124": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 3, "C": 4, "D": 2,
          },
          "0125": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 6,"S2": 6, "S1": 6,
              "A": 6, "B": 6, "C": 8, "D": 4,
          },
          "0126": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 4, "D": 3,
          },
          "0127": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 2, "B": 2, "C": 3, "D": 2,
          },
          "0128": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 3, "D": 2,
          },
          "0129": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 6,"S2": 6, "S1": 6,
              "A": 6, "B": 6, "C": 7, "D": 4,
          },
          "0130": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 3, "D": 2,
          },
          "0131": {
              "S6": 6, "S5": 6, "S4": 6,
              "S3": 6,"S2": 6, "S1": 6,
              "A": 6, "B": 6, "C": 7, "D": 4,
          },


          "0201": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "0202": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 1, "D": 0,
          },
          "0203": {
              "S6": 6, "S5": 6, "S4": 6,
              "S3": 6,"S2": 6, "S1": 6,
              "A": 6, "B": 6, "C": 5, "D": 1,
          },
          "0204": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 1,
          },
          "0205": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 5, "C": 4, "D": 3,
          },
          "0206": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 4, "D": 3,
          },
          "0207": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 5, "C": 6, "D": 2,
          },
          "0208": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 4, "C": 5, "D": 2,
          },
          "0209": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 4, "D": 2,
          },
          "0210": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 3, "C": 5, "D": 4,
          },
          "0211": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 4, "C": 2, "D": 2,
          },
          "0212": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 5, "D": 4,
          },
          "0213": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 3, "D": 2,
          },
          "0214": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 5, "C": 5, "D": 2,
          },
          "0215": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 4, "C": 5, "D": 2,
          },
          "0216": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 5, "C": 6, "D": 4,
          },
          "0217": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 4, "C": 5, "D": 3,
          },
          "0218": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 4, "C": 3, "D": 1,
          },
          "0219": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 6,"S2": 6, "S1": 6,
              "A": 6, "B": 4, "C": 5, "D": 5,
          },
          "0220": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 3, "C": 4, "D": 4,
          },
          "0221": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 3, "D": 2,
          },
          "0222": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 5, "D": 2,
          },
          "0223": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 2,
          },
          "0224": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 5, "C": 7, "D": 4,
          },
          "0225": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 6,"S2": 6, "S1": 6,
              "A": 6, "B": 5, "C": 4, "D": 3,
          },
          "0226": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 4, "D": 3,
          },
          "0227": {
              "S6": 13, "S5": 13, "S4": 13,
              "S3": 13,"S2": 13, "S1": 13,
              "A": 13, "B": 13, "C": 14, "D": 11,
          },
          "0228": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 3, "D": 2,
          },



          "0301": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "0302": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 1,
          },
          "0303": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 4, "D": 3,
          },
          "0304": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 3, "C": 3, "D": 2,
          },
          "0305": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 4, "C": 5, "D": 4,
          },
          "0306": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 4, "D": 2,
          },
          "0307": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 4, "B": 4, "C": 2, "D": 2,
          },
          "0308": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 3, "C": 3, "D": 1,
          },
          "0309": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 4, "D": 2,
          },
          "0310": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 3, "B": 4, "C": 4, "D": 2,
          },
          "0311": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 3, "D": 3,
          },
          "0312": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 4, "D": 3,
          },
          "0313": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 5, "D": 3,
          },
          "0314": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 4, "D": 3,
          },
          "0315": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 4, "C": 5, "D": 3,
          },
          "0316": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 1, "C": 3, "D": 3,
          },
          "0317": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 6,"S2": 6, "S1": 6,
              "A": 6, "B": 6, "C": 5, "D": 4,
          },
          "0318": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 3, "D": 2,
          },
          "0319": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 5, "C": 6, "D": 3,
          },
          "0320": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 3, "D": 2,
          },
          "0321": {
              "S6": 35, "S5": 35, "S4": 35,
              "S3": 35,"S2": 35, "S1": 35,
              "A": 36, "B": 36, "C": 36, "D": 3,
          },
          "0322": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 5, "C": 7, "D": 3,
          },
          "0323": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 4, "D": 3,
          },
          "0324": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 4, "C": 3, "D": 3,
          },
          "0325": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 5, "D": 4,
          },
          "0326": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 1,
          },
          "0327": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 3, "D": 2,
          },
          "0328": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 5, "D": 3,
          },
          "0329": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 6, "D": 2,
          },
          "0330": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 2, "D": 2,
          },
          "0331": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 4, "D": 2,
          },

          "0401": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "0402": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 1, "C": 1, "D": 2,
          },
          "0403": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 1, "C": 1, "D": 1,
          },
          "0404": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 3, "C": 2, "D": 1,
          },
          "0405": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 1,
          },
          "0406": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 4, "D": 2,
          },
          "0407": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 5, "C": 5, "D": 4,
          },
          "0408": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 3, "C": 3, "D": 3,
          },
          "0409": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 3, "C": 1, "D": 1,
          },
          "0410": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 5, "C": 4, "D": 2,
          },
          "0411": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 4, "D": 3,
          },
          "0412": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 3, "D": 2,
          },
          "0413": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 2, "C": 5, "D": 3,
          },
          "0414": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 4, "C": 5, "D": 3,
          },
          "0415": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 4, "D": 2,
          },
          "0416": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 4, "D": 4,
          },
          "0417": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 3, "D": 3,
          },
          "0418": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 5, "C": 4, "D": 4,
          },
          "0419": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 1, "C": 2, "D": 2,
          },
          "0420": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 1, "C": 4, "D": 3,
          },
          "0421": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 2, "D": 2,
          },
          "0422": {
              "S6": 10, "S5": 10, "S4": 10,
              "S3": 9,"S2": 9, "S1": 9,
              "A": 9, "B": 8, "C": 8, "D": 0,
          },
          "0423": {
              "S6": 4,"S5": 4, "S4": 4,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 3, "D": 3,
          },
          "0424": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 3, "D": 1,
          },
          "0425": {
              "S6": 5,"S5": 5, "S4": 5,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 4, "C": 3, "D": 3,
          },
          "0426": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 4, "D": 4,
          },
          "0427": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 2, "C": 5, "D": 3,
          },
          "0428": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 5, "C": 5, "D": 3,
          },
          "0429": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 4, "D": 2,
          },
          "0430": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 2, "D": 2,
          },


          "0501": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "0502": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 1, "D": 1,
          },
          "0503": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 2, "D": 1,
          },
          "0504": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 5, "D": 2,
          },
          "0505": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 3, "C": 3, "D": 3,
          },
          "0506": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 4, "D": 1,
          },
          "0507": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 3, "D": 2,
          },
          "0508": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 3, "D": 2,
          },
          "0509": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 5, "C": 4, "D": 2,
          },
          "0510": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 3, "D": 1,
          },
          "0511": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 4, "D": 4,
          },
          "0512": {
              "S6": 6, "S5": 6, "S4": 6,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 5, "C": 6, "D": 5,
          },
          "0513": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 4, "D": 2,
          },
          "0514": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 4, "D": 2,
          },
          "0515": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 3, "B": 3, "C": 3, "D": 3,
          },
          "0516": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 4, "C": 4, "D": 4,
          },
          "0517": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 2, "C": 4, "D": 2,
          },
          "0518": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 7,"S2": 7, "S1": 7,
              "A": 7, "B": 6, "C": 6, "D": 6,
          },
          "0519": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 4, "D": 2,
          },
          "0520": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 1, "D": 0,
          },
          "0521": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 4, "D": 3,
          },
          "0522": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 1, "C": 3, "D": 1,
          },
          "0523": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 2, "C": 1, "D": 1,
          },
          "0524": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 4, "C": 3, "D": 2,
          },
          "0525": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 3, "D": 2,
          },
          "0526": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 3, "D": 2,
          },
          "0527": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 1, "C": 3, "D": 1,
          },
          "0528": {
              "S6": 6, "S5": 6, "S4": 6,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 3, "C": 3, "D": 3,
          },
          "0529": {
              "S6": 0,"S5": 0, "S4": 0,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 2, "C": 4, "D": 3,
          },
          "0530": {
              "S6": 3,"S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 3, "D": 1,
          },
          "0531": {
              "S6": 2,"S5": 2, "S4": 2,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 5, "C": 4, "D": 3,
          },









      }


    };
  },
  props: {
    rank: {
      type: String,
      required: true
    },
    e_id: {
      type: Number,
      required: true
    },
  },
  computed: {
    calendars() {
      return this.getCalendar();
    },
  },
  methods: {
    isThisMonth(d, i) {
      if(i == 0 && d > 10) { return false }
      if(i > 2) {
        if(d < 10) { return false }
      }
      return true
    },
    getMeterBorder(d, i) {
      var r = ""
      this.days.forEach((day) => {
        var m = moment(day.date, "ddd, DD MMM YYYY hh:mm:ss GMT")

        var a = moment(day.date).format('MMDD')
        var eve = 0
        if(this.rank != "E1") {
          if(this.eventLast[a] == undefined) {
            eve = 0
          } else {
            if(this.rank.indexOf('A') != -1) {
              eve = this.eventLast[a]["A"]
            } else if(this.rank.indexOf('B') != -1) {
              eve = this.eventLast[a]["B"]
            } else if(this.rank.indexOf('C') != -1) {
              eve = this.eventLast[a]["C"]
            } else if(this.rank.indexOf('D') != -1) {
              eve = this.eventLast[a]["D"]
            } else {
              eve = this.eventLast[a][this.rank]
            }
          }
        } else {
          eve = 0
        }

        if(d == m.format("DD")) {
          if(this.isThisMonth(d, i)) {
              r = "<span style='color: #F9A599'>+2</span> " + (day.top / 1000).toFixed(0) +"k \n" + "<span style='color: #F9A599'>+1</span> " + (day.upper / 1000).toFixed(0) + "k \n" + "<span style='color: #F9A599'>+0</span> " + (day.lower / 1000).toFixed(0) + "k \n<span style='font-size: 80%'>イベラス数:" + eve.toString() + "</span>"
          }
        }
      })
      return r
    },
    onChangeMonth() {
      this.prevMonth(this.currentMonth)
    },
    getStartDate() {
      let date = moment(this.currentDate);
      date.startOf("month");
      const youbiNum = date.day();
      return date.subtract((youbiNum - 1), "days");
    },
    getEndDate() {
      let date = moment(this.currentDate);
      date.endOf("month");
      const youbiNum = date.day();
      return date.add(6 - youbiNum, "days");
    },
    getCalendar() {
      let startDate = this.getStartDate();
      console.log(startDate.format("d"))
      if(startDate.get("date") == 2) {
        startDate = startDate.subtract(7, "days")
      }
      const endDate = this.getEndDate();
      const weekNumber = Math.ceil(endDate.diff(startDate, "days") / 7);

      let calendars = [];
      for (let week = 0; week < weekNumber; week++) {
        let weekRow = [];
        for (let day = 0;  day < 7; day++) {
          weekRow.push({
            date: startDate.get("date"),
          });
          startDate.add(1, "days");
        }
        calendars.push(weekRow);
      }
      return calendars;
    },
    nextMonth() {
        this.currentDate = moment(this.currentDate).add(1, "month");
    },
    prevMonth(n) {
        this.currentDate = moment().add(n, "month");
        this.fetchMeterBorder()
    },
    fetchMeterBorder() {
      var m = this.currentDate.format("M")
      var y = this.currentDate.format("YYYY")
      axios
        .get("https://line.pinolive-analysis.com/api/v1/meter_border?rank=" + this.rank + "&e_id=" + this.e_id + "&month=" + m + "&year=" + y, {headers: {'Authorization': this.idToken}})
        .then((res) => {
           this.days = res.data.days
        })
    }


  },
  mounted() {
    this.fetchMeterBorder()

    var n = moment()
    var n2 = moment().add(-1, 'months')
    var n3 = moment().add(-2, 'months')
    var n4 = moment().add(-3, 'months')
    this.daySelect = [
      {label: n.format("Mo").replace("th", "").replace("st", "").replace("nd", "").replace("rd", ""), value: 0},
      {label: n2.format("Mo").replace("th", "").replace("st", "").replace("nd", "").replace("rd", ""), value: -1},
      {label: n3.format("Mo").replace("th", "").replace("st", "").replace("nd", "").replace("rd", ""), value: -2},
      {label: n4.format("Mo").replace("th", "").replace("st", "").replace("nd", "").replace("rd", ""), value: -3},
    ]
  },
  watch: {
    rank: function() {
      this.fetchMeterBorder()
    },
    e_id: function() {
      this.fetchMeterBorder()
    }
  }
}
</script>

<style scoped lang="scss">
.calendar {
  max-width: 900px;
  border-left: 0px solid #CBCDCF;
  .week_raw {
    display: flex;
    border-left: 1px solid #CBCDCF;

    .day {
      flex:1;
      min-height:80px;
      border-right: 1px solid #CBCDCF;
      border-bottom: 1px solid #CBCDCF;

      .meterborder {
        font-size: 9px;
        margin-bottom: 10px;
        margin-top: 5px;
        font-weight: 800;
        white-space: break-spaces;
      }
    }
  }

  .ofWeek_raw {
    display: flex;
    border: 0px solid #CBCDCF;
    border-bottom: 1px solid #CBCDCF;

    .dayOfWeek {
      flex:1;
      font-size: 12px;
      min-height: 25px;
      max-height: 25px;
      border-top: 0px;

      p {
        height: 100%;
        margin-top: 4px;
        color: #333;
      }
    }
  }
}

.small-select {
  font-size: 1rem;
}
.gray_out {
  color: #E0E0E0;
}
.w100 {
  width: 100%
}
.text-left {
  text-align: left;
}

</style>
