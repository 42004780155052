<template>
  <div class="home">
    <div class="container p-0">
      <div class="top">
        <h1 class="pb-3">ライバー助けるくん</h1>
        <p>配信アプリ「Pococha」のランクメーターが<br/>スグに、いつでも、手軽に見れる</p>
      </div>
      <div class="pol-card top-card mx-4 py-3 px-2">
        <p class="main-c">LINEで友達追加後、スグに使える！</p>
        <button class="btn btn-main-c btn-lg"><a href="https://lin.ee/cNJnKo8">早速使ってみる</a></button>
      </div>
      <div class="feature">
        <h1 class="py-2 headline">３つの特徴</h1>

        <div class="simple-card pt-3 px-3 pb-0 mx-2 my-4">
           <div class="row">
               <div class="feature-card-box-index">
                 <span class="feature-index">01</span>
               </div>
               <div class="col-4 px-0">
                  <img class="w100" src="../assets/feature1.png"/>
               </div>
               <div class="col-8">
                   <h3 class="mt-2">LINEアプリで手軽に見れる</h3>
                   <p>普段お使いのLINEから簡単に、見たい「ランク」「締め時間」のメータを見ることができる</p>
               </div>
           </div>
        </div>

        <div class="simple-card pt-3 px-3 pb-0 mx-2 my-4">
           <div class="row">
               <div class="feature-card-box-index">
                 <span class="feature-index">02</span>
               </div>
               <div class="col-4 px-0">
                  <img class="w100" src="../assets/feature2.png"/>
               </div>
               <div class="col-8">
                   <h3 class="mt-2">今日のメーター予測を見れる</h3>
                   <p>過去のメーターデータから今日の予測データを見ることができる。</p>
               </div>
           </div>
        </div>
        <div class="simple-card pt-3 px-3 pb-0 mx-2 my-4">
           <div class="row">
               <div class="feature-card-box-index">
                 <span class="feature-index">03</span>
               </div>
               <div class="col-4 px-0">
                  <img class="w100" src="../assets/feature3.png"/>
               </div>
               <div class="col-8">
                   <h3 class="mt-2">過去のメーターを見れる</h3>
                   <p>過去の1ヶ月分の+0, +1, +2のメーター情報を見て振り返りができる。</p>
               </div>
           </div>
        </div>
      </div>
      <div class="steps py-3">
        <h1 class="py-2 headline main-text-c">簡単に設定！３ステップで！</h1>
        <div class="simple-card bg-white p-2 m-4 ">
           <div class="row">
               <div class="col-4">
                  <img class="w100" src="../assets/step1.png"/>
               </div>
               <div class="col-8  px-0">
                   <h3 class="mt-2">アカウントを登録</h3>
                   <p>LINEにログインしてアカウントを作成</p>
               </div>
           </div>
        </div>
        <div class="simple-card bg-white p-2 m-4 ">
           <div class="row">
               <div class="col-4">
                  <img class="w100" src="../assets/step2.png"/>
               </div>
               <div class="col-8  px-0">
                   <h3 class="mt-2">必須項目を登録</h3>
                   <p>「ライバー」「リスナー」さん毎に<br/>メーター閲覧に必要な情報を入力します！</p>
               </div>
           </div>
        </div>
        <div class="simple-card bg-white p-2 m-4 ">
           <div class="row">
               <div class="col-4">
                  <img class="w100" src="../assets/step3.png"/>
               </div>
               <div class="col-8  px-0">
                   <h3 class="mt-2">メーターを確認</h3>
                   <p>毎日のメーター通知を「ON」に設定し、メーターツールを使用</p>
               </div>
           </div>
        </div>

        <h1 class="py-2 headline main-text-c">Q&A</h1>
        <div class="simple-card bg-white p-3 m-4 text-left ">
           <div class="row">
               <div class="col-12  px-2">
                   <h3 class="mt-2">Q. 無料で使えるの？</h3>
                   <h3 class="mt-2">A. はい。全て無料で利用可能です。</h3>
               </div>
           </div>
        </div>
        <div class="simple-card bg-white p-3 m-4 text-left ">
           <div class="row">
               <div class="col-12  px-2">
                   <h3 class="mt-2">Q. ライバーではなくても使えるの？</h3>
                   <h3 class="mt-2">A. はい。リスナーさんでも利用可能です。</h3>
               </div>
           </div>
        </div>
        <div class="simple-card bg-white p-3 m-4 text-left ">
           <div class="row">
               <div class="col-12  px-2">
                   <h3 class="mt-2">Q. どのように予測しているの？</h3>
                   <h3 class="mt-2">A. 同曜日４週間の平均で出しています。今後、月初やイベント終日も考慮した予測数値に改良予定です。</h3>
               </div>
           </div>
        </div>
      </div>
      <div class="sub-footer text-center">
        <div class="pol-card mx-4 py-3 px-2">
          <p class="main-c">LINEで友達追加後、スグに使える！</p>
          <button class="btn btn-main-c btn-lg"><a href="https://lin.ee/cNJnKo8">早速使ってみる</a></button>
        </div>
      </div>
      <div class="footer">
        <h2>ライバー助けるくん</h2>
        <p><a href="/terms_of_service">利用規約</a></p>
        <p><a href="https://vectorinc.co.jp/privacy">プライバシーポリシー</a></p>
        <p><a href="https://liverbank.co.jp/">運営会社</a></p>
        <p class="text-center w100 pb-3 text-b">Powered by pino live</p>
      </div>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { Auth, Hub } from "aws-amplify";

export default {
  name: 'Home',
  data() {
  },
  components: {
  },
  async beforeCreate() {
    //サインインイベントフックを追加
    Hub.listen("auth", async (data) => {
      switch (data.payload.event) {
        case "signIn": { // サインインイベントをフック
          const cognitoUser = await Auth.currentAuthenticatedUser();
          var idToken = cognitoUser.signInUserSession.idToken.jwtToken 
          console.log(`signed in ... ${cognitoUser.username}`);
          localStorage.setItem('idToken', idToken)
          localStorage.setItem('name', cognitoUser.attributes.name)

          this.$router.replace({ path: '/mypage' })

          break;
        }
        default:
          break;
      }
    });
  },
  methods: {
    is_authenticated() {
      var idToken = localStorage.getItem("idToken")
      if(idToken != null) {
        return true
      } else {
        return false
      }
    },
    async signIn(provider) {
      this.$store.dispatch("loading", true); 
      const res = await Auth.federatedSignIn({ provider });
      console.log(res);
    }
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">

h1 {
  font-size: 17px;
}
h2 {
  font-size: 15px;
}
.headline {
  font-size: 18px;
  font-weight: 800;
}

.top {
  background: #FB8A7E;
  padding: 55px 10px;
  text-align: left;
  h1 {
    color: #F6F6F6;
    font-weight: bold;
    font-size: 15px;
  }
  p {
    color: #F6F6F6;
    font-size: 17px;
    font-weight: bold;
  }
}
.pol-card {
  box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.2);
  background: white;
  border-radius: 15px;
  p {
    font-size: 13px;
    font-weight: 800;
  }
}
.top-card {
  position: relative;
  top: -30px;
  p {
    font-size: 13px;
    font-weight: 800;
  }
}

.simple-card {
  border-radius: 15px;
  border: 1px solid #E7E6E6;
  text-align: left;

  h3 {
    font-size: 15px;
    font-weight: 700;
  }
  p {
    font-size: 12px;
    color: #A7A7A7;
    font-weight: 300;
    margin-bottom: 10px;
  }
  .feature-card-box-index {
    position: absolute;
    text-align: center;

    .feature-index {
      color: #333;
      font-size: 28px;
      text-align: center;
      width: 100%;
      font-weight: bold;
      position: relative;
      top: -36px;
      background-color: white;
      padding: 0px 7px;
    }
  }
}
.sub-footer {
  background: #FB8A7E;
  padding: 35px 5px;
  text-align: left;
}
.footer {
  background: #353330;
  padding: 25px 10px;
  padding-bottom: 0px;
  text-align: left;

  color: white;
  p, a {
    font-size: 12px;
    margin-top: 3px;
    margin-bottom: 5px;
    text-decoration: none;
    color: white;
  }
}
.steps {
  background-color: #FFF3F2;
}
.w100 {
  width: 100%;
}
.text-b {
  font-weight: 800;
}
</style>
