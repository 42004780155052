
<template>
  <div class="home">
    <div class="container p-0">
      <div class="sub-footer text-center pt-5">
        <div class="pol-card mx-4 py-3 px-2">
          <p class="bold">LINEで登録してスタート</p>
          <amplify-authenticator v-bind:authConfig="authConfig"/>
          <button @click="signIn('LINE')" class="btn btn-main-c login">LINEでログイン</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { Auth, Hub } from "aws-amplify";

export default {
  name: 'Home',
  data() {
  },
  components: {
  },
  async beforeCreate() {
    Hub.listen("auth", async (data) => {
      switch (data.payload.event) {
        case "signIn": { // サインインイベントをフック
          const cognitoUser = await Auth.currentAuthenticatedUser();
          var idToken = cognitoUser.signInUserSession.idToken.jwtToken 
          localStorage.setItem('idToken', idToken)
          localStorage.setItem('name', cognitoUser.attributes.name)

          this.$router.replace({ path: '/mypage' })

          break;
        }
        default:
          break;
      }
    });
  },
  methods: {
    is_authenticated() {
      var idToken = localStorage.getItem("idToken")
      if(idToken != null) {
        return true
      } else {
        return false
      }
    },
    async signIn(provider) {
      this.$store.dispatch("loading", true); 
      const res = await Auth.federatedSignIn({ provider });
      console.log(res);
    }
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">
.login {
    padding: 35px;
    border-radius: 8px;
    box-shadow: 3px 3px 2px rgb(0 0 0 / 20%);
    font-size: 20px;
    font-weight: bold;
    background-color: #08C656;
}
.bold {
    font-weight: 600;
}

</style>
