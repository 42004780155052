

<template>
  <div class="home">
    <div class="container">
    </div>
    <nav class="navbar navbar-light bg-white border-bottom shadow-sm">
      <div class="container">
        <a class="navbar-brand" href="mypage">
          <img  width="40" height="40" src="../assets/logo.png"/>
        </a>
        <a class="navbar-brand" href="mypage"><b>ライバー助けるくん</b></a>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" href="setting">設定</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click="signout()">ログアウト</a>
            </li>
          </ul>
        </div>

      </div>
    </nav>


    <template v-if="is_authenticated()">
      <div class="container setting-form-bg">

      <div class="col-12 text-left setting-form">
        <label v-show="user.user_type=='ライバー'" class="pb-1 pt-4">ライバー名</label>
        <label v-show="user.user_type=='リスナー'" class="pb-1 pt-4">リスナー名</label>
        <div class="input-group mb-3 form-group">
          <input id="name" v-model="user.name" type="text" class="form-control lv-form">
          <div id="name-err" class="invalid-feedback no-disp">
            この項目は必須となります
          </div>
        </div>

        <label class="pb-1">お住まいの地域</label>
        <div class="input-group mb-3">
          <select class="form-select lv-form" v-model="user.living">
            <option>北海道</option>
            <option>青森</option>
            <option>岩手</option>
            <option>宮城</option>
            <option>秋田</option>
            <option>山形</option>
            <option>福島</option>
            <option>茨城</option>
            <option>栃木</option>
            <option>群馬</option>
            <option>埼玉</option>
            <option>千葉</option>
            <option>東京</option>
            <option>神奈川</option>
            <option>新潟</option>
            <option>富山</option>
            <option>石川</option>
            <option>福井</option>
            <option>山梨</option>
            <option>長野</option>
            <option>岐阜</option>
            <option>静岡</option>
            <option>愛知</option>
            <option>三重</option>
            <option>滋賀</option>
            <option>京都</option>
            <option>大阪</option>
            <option>兵庫</option>
            <option>奈良</option>
            <option>和歌山</option>
            <option>鳥取</option>
            <option>島根</option>
            <option>岡山</option>
            <option>広島</option>
            <option>山口</option>
            <option>徳島</option>
            <option>香川</option>
            <option>愛媛</option>
            <option>高知</option>
            <option>福岡</option>
            <option>佐賀</option>
            <option>長崎</option>
            <option>熊本</option>
            <option>大分</option>
            <option>宮崎</option>
            <option>鹿児島</option>
            <option>沖縄</option>
          </select>
          <div id="gender-err" class="invalid-feedback no-disp">
            この項目は必須となります
          </div>
        </div>


        <label class="pb-1">メールアドレス</label>
        <div class="input-group mb-3">
          <input id="phone" v-model="user.email" type="text" class="form-control lv-form">
          <div id="phone-err" class="invalid-feedback no-disp">
            この項目は必須となります
          </div>
        </div>

        <label v-show="user.user_type=='ライバー'" class="pb-1">所属</label>
        <div v-show="user.user_type=='ライバー'" class="input-group mb-3">
          <ul class="select-form">
              <li @click="onClickPocochaTag('フリーライバー')" class="item px-4" :class="isPocochaClicked('フリーライバー')">フリーライバー</li>
              <li @click="onClickPocochaTag('事務所所属')" class="item px-4" :class="isPocochaClicked('事務所所属')">事務所所属</li>
          </ul>
        </div>

        <label v-show="user.user_type=='リスナー'" class="pb-1">現在推しのライバーさん</label>
        <div   v-show="user.user_type=='リスナー'" class="input-group mb-3">
          <input id="name" v-model="user.fan_liver" type="text" class="form-control lv-form">
        </div>


        <label v-show="user.user_type=='ライバー'"  class="pb-1">過去最高ランク</label>
        <div  v-show="user.user_type=='ライバー'" class="input-group mb-3">
          <select id="pococha_type" class="form-select lv-form" v-model="user.best_rank">

             <option value="S6" selected>S6</option>
             <option value="S5">S5</option>
             <option value="S4">S4</option>
             <option value="S3">S3</option>
             <option value="S2">S2</option>
             <option value="S1">S1</option>
             <option value="A3">A3</option>
             <option value="A2">A2</option>
             <option value="A1">A1</option>
             <option value="B3">B3</option>
             <option value="B2">B2</option>
             <option value="B1">B1</option>
             <option value="C3">C3</option>
             <option value="C2">C2</option>
             <option value="C1">C1</option>
             <option value="D3">D3</option>
             <option value="D2">D2</option>
             <option value="D1">D1</option>
             <option value="E">E</option>
          </select>
          <div id="phone-err" class="invalid-feedback no-disp">
            この項目は必須となります
          </div>
        </div>

        <label v-show="user.user_type=='ライバー'"  class="pb-1">配信活動においての悩み</label>
        <div v-show="user.user_type=='ライバー'" class="input-group mb-3">
          <div v-for="t in troubles" class="form-check w100" :key="t">
            <input class="form-check-input" type="checkbox" :value="t" @change="addTroubles(t)" :checked="isCheckTroubles(t)" >
            <label class="form-check-label" for="flexCheckDefault">{{ t }}</label>
          </div>

          <div id="phone-err" class="invalid-feedback no-disp">
            この項目は必須となります
          </div>
        </div>

        <label v-show="user.user_type=='ライバー'"  class="pb-1">その他のお悩み事項</label>
        <div v-show="user.user_type=='ライバー'"  class="input-group mb-3">
          <textarea id="pococha_type" class="form-control lv-form" v-model="worry"></textarea>
        </div>


        <label  v-show="user.user_type=='ライバー'" for="basic-url">LINE通知</label>
        <div v-show="user.user_type=='ライバー'" class="input-group mb-3">
          <select id="pococha_type" class="form-control" v-model="user.is_notify">
            <option value="False">通知OFF</option>
            <option value="True" selected>通知ON</option>
          </select>
          <div id="phone-err" class="invalid-feedback no-disp">
            この項目は必須となります
          </div>
        </div>

      </div>
      <div class="col-12 text-center">
        <button @click="register" type="button" class="btn btn-main-c mt-3 mb-3">更  新</button>
      </div>



      </div>
    </template>
    
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import { Auth } from "aws-amplify";

export default {
  name: 'Home',
  data() {
    return {
      idToken: localStorage.getItem("idToken"),
      user: {},
      gender: "",
      pococha_type: "",
      years: [],
      years2: [],
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      days: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
      year: 1990,
      month: 6,
      day: 1,
      liv_since_year: 2019,
      liv_since_month: 6,
      lis_since_year: 2019,
      lis_since_month: 6,
      worry: "",
      trouble: [],
      troubles: [
        "ランクが上がらない", 
        "新規リスナーさんが来ない", 
        "配信を本業に活かすことができていない", 
        "リスナートラブルが解決できない", 
        "病んだ時、落ち込んだ時の対処法が分からない", 
        "企画を考えるのが大変", 
        "配信について話せる知人がいない", 
        "特典の発送や受け取りが大変", 
        "確定申告など、税金周りをどう対応したらいいか分からない", 
      ]
    }
  },
  components: {
  },
  methods: {
    isCheckTroubles: function(t) {
      var i = this.trouble.indexOf(t)
      if(i != -1) {
        return true
      } else {
        return false
      }
    },
    addTroubles: function(t) {
      var i = this.trouble.indexOf(t)

      if(i != -1) {
        this.trouble.splice(i, 1)
      } else {
        this.trouble.push(t)
      }
    },
    onClickPocochaTag: function(n) {
      this.pococha_type = n 
    },
    isPocochaClicked: function(n) {
      return (this.pococha_type == n)? "active-tag" : ""
    },
    onClickGenderTag: function(n) {
      this.gender = n
    },
    isGenderClicked: function(n) {
      return (this.gender == n)? "active-tag" : ""
    },
    async signout() {
      try {
        await Auth.signOut();
        this.$router.push("/");
      } catch (e) {
        console.log(e);
      }
    },
    is_authenticated() {
      var idToken = localStorage.getItem("idToken")
      if(idToken != null) {
        return true
      } else {
        return false
      }
    },
    register() {
      this.user.gender = this.gender
      this.user.birthday = String(this.year) + "-" + String(this.month) + "-" + String(this.day)
      if(this.user.user_type =='ライバー') {
        this.user.liver_since = String(this.liv_since_year) +"-" + String(this.liv_since_month)
      } else {
        this.user.listenerr_since = String(this.lis_since_year) +"-" + String(this.lis_since_month)
      }
      this.user.worry = this.trouble.join("/")  + "/" + this.worry

      axios
        .patch("https://line.pinolive-analysis.com/api/v1/user", {"user": this.user}, {headers: {'Authorization': this.idToken}})
        .then(() => {
            this.$notify("更新しました。");
            this.$router.replace({ path: '/mypage' })
        })
    },
    forecastMeterBorder(rank, e_id) {
      axios
        .get("https://line.pinolive-analysis.com/api/v1/meter_border?rank=" + rank + "&e_id=" + e_id, {headers: {'Authorization': this.idToken}})
        .then((res) => {
           this.forecast = res.data.result
           this.days = res.data.days
        })
    }
  },
  mounted() {
    axios
      .get("https://line.pinolive-analysis.com/api/v1/user/index", {headers: {'Authorization': this.idToken}})
      .then((res) => {
         this.user = res.data
         this.gender = this.user.gender
         var b = this.user.birthday.split("-")
         this.year = b[0]
         this.month = b[1]
         this.day = b[2]
         this.trouble = this.user.worry.split("/")
         this.worry = this.trouble.slice(-1)[0]
         this.pococha_type = this.user.pococha_type


         if(this.user.user_type == 'ライバー') {
           var c = this.user.liver_since.split("-")
           this.liv_since_year = c[0]
           this.liv_since_month = c[1]
         } else {
           var d = this.user.listenerr_since.split("-")
           this.lis_since_year = d[0]
           this.lis_since_month = d[1]
         }

      })

    for (let i = 1950; i < 2016; i++) {
      this.years.push(i)
    }
    for (let i = 2015; i < 2022; i++) {
      this.years2.push(i)
    }
  }
}
</script>

<style scoped lang="scss">
.home {
  background: #FEFEFE;
}
.text-left {
  text-align: left;
}
.select-form {
  list-style: none;
  position: relative;
  display: contents;
  .item {
    border-radius: 8px;
    border: 1px solid lightgrey;
    padding: 8px 30px;
    margin: 0px 5px;
    font-weight: 600;
    font-size: 12px;
    color: #7F7F7F;
    background-color: #FFF;
  }
  .active-tag {
    background-color: #FB8A7E;
    color: white;
    border: 1px solid #FB8A7E;
  }
}
.setting-form {
  label {
    font-size: 13px;
    font-weight: 800;
    color: #333;
    padding-top: 4px;

    small {
      color: #535353;
      font-size: 70%;
      padding: 0px 4px;
    }
  }
}
.setting-form-bg {
  background-color: #FFF9F8;
}
.lv-form {
  font-size: 13px;
  color: #7F7F7F;
  border: 1px solid #EAE8E8;
}
.ouvo-select-input {
    font-size: 14px;
    color: #7F7F7F;
    border: 1px solid #EAE8E8;
    padding: 7px 20px;
    padding-left: 5px;
    line-height: 1.5;
    border-radius: 4px;
}
.birthday-form span{
   padding-right: 8px;
   padding-left: 5px;
}
.w100 {
  width: 100%;
}
</style>
