/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:9e208e2a-afb8-4120-ac62-31d6379059fc",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_3L9qxHGQr",
    "aws_user_pools_web_client_id": "2k45lifc9c87ldth3v1dbv1mr5",
    "oauth": {
        "domain": "lineliverappe3a11805-e3a11805-dev.auth.ap-northeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        //"redirectSignIn": "http://localhost:8080/",
        //"redirectSignOut": "http://localhost:8080/",
        "redirectSignIn": "https://liver.pinolive-analysis.com/login",
        "redirectSignOut": "https://liver.pinolive-analysis.com/login",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_login_mechanisms": [
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_signup_attributes": [
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    }
};


export default awsmobile;
