
<template>
  <div class="home">
    <div class="container">
    </div>
    <nav class="navbar navbar-light bg-white border-bottom shadow-sm">
      <div class="container">
        <a class="navbar-brand" href="mypage">
          <img  width="40" height="40" src="../assets/logo.png"/>
        </a>
        <a class="navbar-brand" href="mypage"><b>ライバー助けるくん</b></a>

        <button v-if="mode=='member'" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div v-if="mode=='member'" class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" href="setting">設定</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click="signout()">ログアウト</a>
            </li>
          </ul>
        </div>
        <a v-if="mode == 'not_member'"></a>
      </div>
    </nav>

    <div v-if="mode == 'member'">
    <template v-if="is_authenticated()">
      <div class="container">
        <h3 class="today_label mb-3 mt-3">{{ today }}</h3>
        <div class="container">
            <div class="row">
                <div class="col-3 text-left px-1 pl-0">
                   <select v-model="rank" class="form-select form-select-sm small-select" aria-label="" @change="onChangeRank()">
                       <option value="S6" selected>S6</option>
                       <option value="S5">S5</option>
                       <option value="S4">S4</option>
                       <option value="S3">S3</option>
                       <option value="S2">S2</option>
                       <option value="S1">S1</option>
                       <option value="A3">A3</option>
                       <option value="A2">A2</option>
                       <option value="A1">A1</option>
                       <option value="B3">B3</option>
                       <option value="B2">B2</option>
                       <option value="B1">B1</option>
                       <option value="C3">C3</option>
                       <option value="C2">C2</option>
                       <option value="C1">C1</option>
                       <option value="D3">D3</option>
                       <option value="D2">D2</option>
                       <option value="D1">D1</option>
                       <option value="E1">E1</option>
                   </select>
                </div>
                <div class="col-3 text-left px-1">
                   <select v-model="e_id" class="form-select form-select-sm small-select" aria-label="" @change="onChangeRank()">
                     <option value="0" selected>0時</option>
                     <option value="22">22時</option>
                     <option value="13">13時</option>
                   </select>
                </div>
                <div class="col-6">
                </div>
            </div>
        </div>



        <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item " v-for="(f, ind) in forecast" :key="f" :class="isActive(ind)">
                <div class="weekly_card mx-5 my-3 p-2 pt-1">
                  <p class="card_title my-2">{{ cardTitle(ind) }}</p>
                  <p class="plus2 my-0 "><span class="index px-3">+2</span><span class="value">{{ formatNum(f[0]) }}</span>k ~ <span class="value">{{ formatNum(f[0] * 1.1) }}</span>k</p>
                  <p class="plus2 my-0 "><span class="index px-3">+1</span><span class="value">{{ formatNum(f[1]) }}</span>k ~ <span class="value">{{ formatNum(f[1] * 1.1) }}</span>k</p>
                  <p class="plus2 my-0 "><span class="index px-3">+0</span><span class="value">{{ formatNum(f[2]) }}</span>k ~ <span class="value">{{ formatNum(f[2] * 1.1) }}</span>k</p>

                  <p class="text-center mb-0 mt-2">イベラス数: {{ getEventLast(ind, rank) }}</p>
                </div>
            </div>
          </div>
          <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-bs-slide="prev">
            <span class="carousel-control-icon"> <i class="bi bi-caret-left-fill"></i></span>
            <span class="visually-hidden">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-bs-slide="next">
            <span class="carousel-control-icon"><i class="bi bi-caret-right-fill"></i></span>
            <span class="visually-hidden">Next</span>
          </a>
        </div>
        <Calendar :rank="rank" :e_id="e_id" />
        <div class="py-2">
          <p>※過去のデータは、最新で2日前までのデータが反映されます。</p>
        </div>

      </div>
    </template>
    </div>
    <div v-if="mode=='not_member'" class="container">
      <div class="container pt-3">
          <h2 class="mb-3 mt-5">初期登録が完了しておりません。<br/>LINEの方から、プロフィール登録を完了してからご利用ください。</h2>
          <a class="nav-link" @click="signout()">再ログイン</a>
      </div>
    </div>
 
    <div class="footer pt-4 mt-4" :class="isNotMember()">
      <h2>ライバー助けるくん</h2>
    
      <p><a href="/terms_of_service">利用規約</a></p>
      <p><a href="https://vectorinc.co.jp/privacy">プライバシーポリシー</a></p>
      <p><a href="https://liverbank.co.jp/">運営会社</a></p>
      <p class="text-center w100 pb-3 text-b">Powered by pino live</p>
    </div>

   
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import Calendar from '@/components/Calendar.vue'
import { Auth, Hub } from "aws-amplify";
import moment from 'moment'

export default {
  name: 'Home',
  data() {
    return {
      idToken: localStorage.getItem("idToken"),
      days: [],
      forecast: [],
      today: "",
      rank: "",
      e_id: 0,
      mode: "member",

      eventLast: {
          "0801": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "0802": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "0803": {
              "S6": 13, "S5": 13, "S4": 13,
              "S3": 15,"S2": 15, "S1": 15,
              "A": 15, "B": 2, "C": 0, "D": 0,
          },
          "0804": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 1, "D": 0,
          },
          "0805": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 3, "D": 3,
          },
          "0806": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 1, "C": 1, "D": 1,
          },
          "0807": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 4, "D": 4,
          },
          "0808": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 14, "C": 17, "D": 1,
          },
          "0809": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 2, "D": 2,
          },
          "0810": {
              "S6": 14, "S5": 14, "S4": 14,
              "S3": 16,"S2": 16, "S1": 16,
              "A": 16, "B": 3, "C": 1, "D": 1,
          },
          "0811": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 10, "D": 8,
          },
          "0812": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 1, "D": 1,
          },
          "0813": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 1, "D": 1,
          },
          "0814": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 3, "D": 2,
          },
          "0815": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 15, "C": 18, "D": 1,
          },
          "0816": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 1,
          },
          "0817": {
              "S6": 17, "S5": 17, "S4": 17,
              "S3": 20,"S2": 20, "S1": 20,
              "A": 20, "B": 5, "C": 1, "D": 1,
          },
          "0818": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 11, "D": 9,
          },
          "0819": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 2, "C": 2, "D": 2,
          },
          "0820": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 2, "C": 3, "D": 3,
          },
          "0821": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 4, "D": 3,
          },
          "0822": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 15, "C": 18, "D": 1,
          },
          "0823": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 1, "D": 1,
          },
          "0824": {
              "S6": 18, "S5": 18, "S4": 18,
              "S3": 21,"S2": 21, "S1": 21,
              "A": 21, "B": 4, "C": 0, "D": 0,
          },
          "0825": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 10, "D": 8,
          },
          "0826": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 1, "C": 1, "D": 1,
          },
          "0827": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 2, "C": 2, "D": 2,
          },
          "0828": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 1, "D": 0,
          },
          "0829": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 1, "D": 0,
          },
          "0830": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 1, "D": 1,
          },
          "0831": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "0901": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "0902": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 9, "D": 9,
          },
          "0903": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 2, "D": 2,
          },
          "0904": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "0905": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 16, "C": 19, "D": 1,
          },
          "0906": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 1, "D": 1,
          },
          "0907": {
              "S6": 19, "S5": 19, "S4": 19,
              "S3": 22,"S2": 22, "S1": 22,
              "A": 23, "B": 6, "C": 4, "D": 2,
          },
          "0908": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 11, "D": 9,
          },
          "0909": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 2,
          },
          "0910": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 1, "D": 1,
          },
          "0911": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 3, "B": 3, "C": 2, "D": 2,
          },
          "0912": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 19, "C": 21, "D": 1,
          },
          "0913": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 3, "B": 3, "C": 2, "D": 2,
          },
          "0914": {
              "S6": 20, "S5": 20, "S4": 20,
              "S3": 23,"S2": 23, "S1": 23,
              "A": 24, "B": 6, "C": 4, "D": 2,
          },
          "0915": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 12, "D": 11,
          },
          "0916": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 1, "C": 1, "D": 1,
          },
          "0917": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 0, "B": 1, "C": 2, "D": 2,
          },
          "0918": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 3, "D": 3,
          },
          "0919": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 20, "C": 22, "D": 1,
          },
          "0920": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 2, "D": 1,
          },
          "0921": {
              "S6": 21, "S5": 21, "S4": 21,
              "S3": 24,"S2": 24, "S1": 24,
              "A": 25, "B": 6, "C": 4, "D": 2,
          },
          "0922": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 12, "D": 10,
          },
          "0923": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 2, "C": 2, "D": 2,
          },
          "0924": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 1, "D": 1,
          },
          "0925": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 3, "D": 3,
          },
          "0926": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 21, "C": 23, "D": 1,
          },
          "0927": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 3, "D": 2,
          },
          "0928": {
              "S6": 23, "S5": 23, "S4": 23,
              "S3": 25,"S2": 25, "S1": 25,
              "A": 26, "B": 6, "C": 4, "D": 2,
          },
          "0929": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 13, "D": 12,
          },
          "0930": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 2, "C": 1, "D": 1,
          },

          "1001": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "1002": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "1003": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "1004": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 2,
          },
          "1005": {
              "S6": 20, "S5": 20, "S4": 20,
              "S3": 22,"S2": 22, "S1": 22,
              "A": 23, "B": 3, "C": 1, "D": 1,
          },
          "1006": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 1, "C": 3, "D": 1,
          },
          "1007": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 3, "D": 2,
          },
          "1008": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 5, "C": 3, "D": 1,
          },
          "1009": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 4, "B": 4, "C": 2, "D": 3,
          },
          "1010": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 21, "C": 24, "D": 1,
          },
          "1011": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 2, "D": 1,
          },
          "1012": {
              "S6": 22, "S5": 22, "S4": 22,
              "S3": 24,"S2": 24, "S1": 24,
              "A": 25, "B": 5, "C": 1, "D": 1,
          },
          "1013": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 13, "D": 12,
          },
          "1014": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 2,
          },
          "1015": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 3, "D": 2,
          },
          "1016": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 3, "D": 2,
          },
          "1017": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 20, "C": 23, "D": 1,
          },
          "1018": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 1, "D": 1,
          },
          "1019": {
              "S6": 23, "S5": 23, "S4": 23,
              "S3": 25,"S2": 25, "S1": 25,
              "A": 26, "B": 6, "C": 1, "D": 1,
          },
          "1020": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 13, "D": 11,
          },
          "1021": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 3, "D": 3,
          },
          "1022": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 4, "D": 1,
          },
          "1023": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 3, "D": 2,
          },
          "1024": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 20, "C": 23, "D": 1,
          },
          "1025": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 1, "D": 1,
          },
          "1026": {
              "S6": 22, "S5": 22, "S4": 22,
              "S3": 24,"S2": 24, "S1": 24,
              "A": 25, "B": 5, "C": 1, "D": 2,
          },
          "1027": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 14, "D": 12,
          },
          "1028": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 2,
          },
          "1029": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 2, "D": 1,
          },
          "1030": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 3, "D": 2,
          },
          "1031": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 20, "C": 22, "D": 1,
          },



          "1101": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "1102": {
              "S6": 20, "S5": 20, "S4": 20,
              "S3": 22,"S2": 22, "S1": 22,
              "A": 22, "B": 2, "C": 0, "D": 0,
          },
          "1103": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 3, "D": 2,
          },
          "1104": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 2,
          },
          "1105": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 1, "D": 1,
          },
          "1106": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 3, "D": 2,
          },
          "1107": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 23, "C": 24, "D": 2,
          },
          "1108": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 3, "B": 3, "C": 1, "D": 1,
          },
          "1109": {
              "S6": 24, "S5": 24, "S4": 24,
              "S3": 26,"S2": 26, "S1": 26,
              "A": 26, "B": 6, "C": 2, "D": 1,
          },
          "1110": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 25, "D": 15,
          },
          "1111": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 2,
          },
          "1112": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 4, "C": 2, "D": 2,
          },
          "1113": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3, 
              "A": 3, "B": 3, "C": 3, "D": 2,
          },
          "1114": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 22, "C": 24, "D": 2,
          },
          "1115": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 3, "D": 3,
          },
          "1116": {
              "S6": 23, "S5": 23, "S4": 23,
              "S3": 25,"S2": 25, "S1": 25,
              "A": 25, "B": 5, "C": 2, "D": 1,
          },
          "1117": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 13, "D": 12,
          },
          "1118": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 2,
          },
          "1119": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 1, "D": 1,
          },
          "1120": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 3, "D": 2,
          },
          "1121": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 22, "C": 24, "D": 2,
          },
          "1122": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 1, "D": 1,
          },
          "1123": {
              "S6": 24, "S5": 24, "S4": 24,
              "S3": 27,"S2": 27, "S1": 27,
              "A": 27, "B": 7, "C": 3, "D": 1,
          },
          "1124": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 14, "D": 14,
          },
          "1125": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 2,
          },
          "1126": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 2,
          },
          "1127": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 3, "D": 2,
          },
          "1128": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 21, "C": 23, "D": 2,
          },
          "1129": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 1, "D": 1,
          },
          "1130": {
              "S6": 24, "S5": 24, "S4": 24,
              "S3": 26,"S2": 26, "S1": 26,
              "A": 26, "B": 6, "C": 2, "D": 1,
          },



          "1201": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "1202": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "1203": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 2,
          },
          "1204": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 3, "D": 3,
          },
          "1205": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 2, "D": 1,
          },
          "1206": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 2,
          },
          "1207": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 2, "D": 1,
          },
          "1208": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 2, "C": 12, "D": 14,
          },
          "1209": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 6,"S2": 6, "S1": 6,
              "A": 6, "B": 4, "C": 4, "D": 2,
          },
          "1210": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 6,"S2": 6, "S1": 6,
              "A": 6, "B": 4, "C": 0, "D": 0,
          },
          "1211": {
              "S6": 7, "S5": 7, "S4": 7,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 6, "C": 6, "D": 2,
          },
          "1212": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 5, "B": 5, "C": 4, "D": 1,
          },
          "1213": {
              "S6": 6, "S5": 6, "S4": 6,
              "S3": 6,"S2": 6, "S1": 6,
              "A": 6, "B": 6, "C": 4, "D": 2,
          },
          "1214": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 6,"S2": 6, "S1": 6,
              "A": 6, "B": 6, "C": 5, "D": 1,
          },
          "1215": {
              "S6": 2, "S5": 2, "S4": 2, 
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 4, "C": 6, "D": 3,
          },
          "1216": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 4, "C": 6, "D": 2,
          },
          "1217": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 3, "B": 4, "C": 4, "D": 3,
          },
          "1218": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 6,"S2": 6, "S1": 6,
              "A": 6, "B": 6, "C": 10, "D": 9,
          },
          "1219": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 5, "C": 6, "D": 3,
          },
          "1220": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 4, "B": 4, "C": 8, "D": 6,
          },
          "1221": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 6, "C": 8, "D": 4,
          },
          "1222": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 6, "C": 9, "D": 5,
          },
          "1223": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 7, "D": 6,
          },
          "1224": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 4, "B": 5, "C": 5, "D": 3,
          },
          "1225": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 2, "D": 2,
          },
          "1226": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 1,
          },
          "1227": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 2, "D": 2,
          },
          "1228": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 2, "D": 1,
          },
          "1229": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "1230": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "1231": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },



          "0101": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "0102": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "0103": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 1, "D": 1,
          },
          "0104": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "0105": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "0106": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "0107": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "0108": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 1, "D": 1,
          },
          "0109": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "0110": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 2,
          },
          "0111": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 1, "C": 2, "D": 1,
          },
          "0112": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 1, "C": 1, "D": 2,
          },
          "0113": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 0, "C": 0, "D": 0,
          },
          "0114": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 3, "C": 2, "D": 2,
          },
          "0115": {
              "S6": 3, "S5": 3, "S4": 3, 
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 5, "D": 3,
          },
          "0116": {
              "S6": 6, "S5": 6, "S4": 6,
              "S3": 7,"S2": 7, "S1": 7,
              "A": 7, "B": 7, "C": 3, "D": 1,
          },
          "0117": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 6,"S2": 6, "S1": 6,
              "A": 6, "B": 7, "C": 7, "D": 2,
          },
          "0118": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 5, "C": 5, "D": 1,
          },
          "0119": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 3, "C": 4, "D": 3,
          },
          "0120": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 3, "D": 2,
          },
          "0121": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 4, "D": 3,
          },
          "0122": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 3, "C": 5, "D": 4,
          },
          "0123": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 5, "B": 6, "C": 5, "D": 4,
          },
          "0124": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 3, "C": 4, "D": 2,
          },
          "0125": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 6,"S2": 6, "S1": 6,
              "A": 6, "B": 6, "C": 8, "D": 4,
          },
          "0126": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 4, "D": 3,
          },
          "0127": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 2, "B": 2, "C": 3, "D": 2,
          },
          "0128": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 3, "D": 2,
          },
          "0129": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 6,"S2": 6, "S1": 6,
              "A": 6, "B": 6, "C": 7, "D": 4,
          },
          "0130": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 3, "D": 2,
          },
          "0131": {
              "S6": 6, "S5": 6, "S4": 6,
              "S3": 6,"S2": 6, "S1": 6,
              "A": 6, "B": 6, "C": 7, "D": 4,
          },


          "0201": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "0202": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 1, "D": 0,
          },
          "0203": {
              "S6": 6, "S5": 6, "S4": 6,
              "S3": 6,"S2": 6, "S1": 6,
              "A": 6, "B": 6, "C": 5, "D": 1,
          },
          "0204": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 1,
          },
          "0205": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 5, "C": 4, "D": 3,
          },
          "0206": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 4, "D": 3,
          },
          "0207": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 5, "C": 6, "D": 2,
          },
          "0208": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 4, "C": 5, "D": 2,
          },
          "0209": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 4, "D": 2,
          },
          "0210": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 3, "C": 5, "D": 4,
          },
          "0211": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 4, "C": 2, "D": 2,
          },
          "0212": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 5, "D": 4,
          },
          "0213": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 3, "D": 2,
          },
          "0214": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 5, "C": 5, "D": 2,
          },
          "0215": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 4, "C": 5, "D": 2,
          },
          "0216": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 5, "C": 6, "D": 4,
          },
          "0217": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 4, "C": 5, "D": 3,
          },
          "0218": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 4, "C": 3, "D": 1,
          },
          "0219": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 6,"S2": 6, "S1": 6,
              "A": 6, "B": 4, "C": 5, "D": 5,
          },
          "0220": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 3, "C": 4, "D": 4,
          },
          "0221": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 3, "D": 2,
          },
          "0222": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 5, "D": 2,
          },
          "0223": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 2,
          },
          "0224": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 5, "C": 7, "D": 4,
          },
          "0225": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 6,"S2": 6, "S1": 6,
              "A": 6, "B": 5, "C": 4, "D": 3,
          },
          "0226": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 4, "D": 3,
          },
          "0227": {
              "S6": 13, "S5": 13, "S4": 13,
              "S3": 13,"S2": 13, "S1": 13,
              "A": 13, "B": 13, "C": 14, "D": 11,
          },
          "0228": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 3, "D": 2,
          },

          "0301": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "0302": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 1,
          },
          "0303": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 4, "D": 3,
          },
          "0304": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 3, "C": 3, "D": 2,
          },
          "0305": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 4, "C": 5, "D": 4,
          },
          "0306": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 4, "D": 2,
          },
          "0307": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 4, "B": 4, "C": 2, "D": 2,
          },
          "0308": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 3, "C": 3, "D": 1,
          },
          "0309": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 4, "D": 2,
          },
          "0310": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 3, "B": 4, "C": 4, "D": 2,
          },
          "0311": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 3, "D": 3,
          },
          "0312": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 4, "D": 3,
          },
          "0313": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 5, "D": 3,
          },
          "0314": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 4, "D": 3,
          },
          "0315": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 4, "C": 5, "D": 3,
          },
          "0316": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 1, "C": 3, "D": 3,
          },
          "0317": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 6,"S2": 6, "S1": 6,
              "A": 6, "B": 6, "C": 5, "D": 4,
          },
          "0318": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 3, "D": 2,
          },
          "0319": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 5, "C": 6, "D": 3,
          },
          "0320": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 3, "D": 2,
          },
          "0321": {
              "S6": 35, "S5": 35, "S4": 35,
              "S3": 35,"S2": 35, "S1": 35,
              "A": 36, "B": 36, "C": 36, "D": 3,
          },
          "0322": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 5, "C": 7, "D": 3,
          },
          "0323": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 4, "D": 3,
          },
          "0324": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 4, "C": 3, "D": 3,
          },
          "0325": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 5, "D": 4,
          },
          "0326": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 1,
          },
          "0327": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 3, "D": 2,
          },
          "0328": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 5, "D": 3,
          },
          "0329": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 6, "D": 2,
          },
          "0330": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 2, "D": 2,
          },
          "0331": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 4, "D": 2,
          },


          "0401": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "0402": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 1, "C": 1, "D": 2,
          },
          "0403": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 1, "C": 1, "D": 1,
          },
          "0404": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 3, "C": 2, "D": 1,
          },
          "0405": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 2, "D": 1,
          },
          "0406": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 4, "D": 2,
          },
          "0407": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 5, "C": 5, "D": 4,
          },
          "0408": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 3, "C": 3, "D": 3,
          },
          "0409": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 3, "C": 1, "D": 1,
          },
          "0410": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 5, "C": 4, "D": 2,
          },
          "0411": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 4, "D": 3,
          },
          "0412": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 3, "D": 2,
          },
          "0413": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 2, "C": 5, "D": 3,
          },
          "0414": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 4, "C": 5, "D": 3,
          },
          "0415": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 4, "D": 2,
          },
          "0416": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 4, "D": 4,
          },
          "0417": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 3, "D": 3,
          },
          "0418": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 5, "C": 4, "D": 4,
          },
          "0419": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 1, "C": 2, "D": 2,
          },
          "0420": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 1, "C": 4, "D": 3,
          },
          "0421": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 2, "D": 2,
          },
          "0422": {
              "S6": 10, "S5": 10, "S4": 10,
              "S3": 9,"S2": 9, "S1": 9,
              "A": 9, "B": 8, "C": 8, "D": 0,
          },
          "0423": {
              "S6": 4,"S5": 4, "S4": 4,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 3, "D": 3,
          },
          "0424": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 3, "D": 1,
          },
          "0425": {
              "S6": 5,"S5": 5, "S4": 5,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 4, "C": 3, "D": 3,
          },
          "0426": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 4, "D": 4,
          },
          "0427": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 2, "C": 5, "D": 3,
          },
          "0428": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 5, "C": 5, "D": 3,
          },
          "0429": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 4, "D": 2,
          },
          "0430": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 2, "D": 2,
          },


          "0501": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 0, "D": 0,
          },
          "0502": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 0,"S2": 0, "S1": 0,
              "A": 0, "B": 0, "C": 1, "D": 1,
          },
          "0503": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 2, "D": 1,
          },
          "0504": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 5, "D": 2,
          },
          "0505": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 3, "C": 3, "D": 3,
          },
          "0506": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 4, "D": 1,
          },
          "0507": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 3, "D": 2,
          },
          "0508": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 3, "D": 2,
          },
          "0509": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 5, "C": 4, "D": 2,
          },
          "0510": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 3, "D": 1,
          },
          "0511": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 4, "D": 4,
          },
          "0512": {
              "S6": 6, "S5": 6, "S4": 6,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 5, "C": 6, "D": 5,
          },
          "0513": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 4, "D": 2,
          },
          "0514": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 3, "C": 4, "D": 2,
          },
          "0515": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 3, "B": 3, "C": 3, "D": 3,
          },
          "0516": {
              "S6": 5, "S5": 5, "S4": 5,
              "S3": 5,"S2": 5, "S1": 5,
              "A": 5, "B": 4, "C": 4, "D": 4,
          },
          "0517": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 2, "C": 4, "D": 2,
          },
          "0518": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 7,"S2": 7, "S1": 7,
              "A": 7, "B": 6, "C": 6, "D": 6,
          },
          "0519": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 4, "D": 2,
          },
          "0520": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 1, "C": 1, "D": 0,
          },
          "0521": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 4, "D": 3,
          },
          "0522": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 1, "C": 3, "D": 1,
          },
          "0523": {
              "S6": 3, "S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 2, "C": 1, "D": 1,
          },
          "0524": {
              "S6": 0, "S5": 0, "S4": 0,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 4, "C": 3, "D": 2,
          },
          "0525": {
              "S6": 1, "S5": 1, "S4": 1,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 2, "C": 3, "D": 2,
          },
          "0526": {
              "S6": 4, "S5": 4, "S4": 4,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 4, "C": 3, "D": 2,
          },
          "0527": {
              "S6": 2, "S5": 2, "S4": 2,
              "S3": 2,"S2": 2, "S1": 2,
              "A": 2, "B": 1, "C": 3, "D": 1,
          },
          "0528": {
              "S6": 6, "S5": 6, "S4": 6,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 3, "C": 3, "D": 3,
          },
          "0529": {
              "S6": 0,"S5": 0, "S4": 0,
              "S3": 1,"S2": 1, "S1": 1,
              "A": 1, "B": 2, "C": 4, "D": 3,
          },
          "0530": {
              "S6": 3,"S5": 3, "S4": 3,
              "S3": 3,"S2": 3, "S1": 3,
              "A": 3, "B": 3, "C": 3, "D": 1,
          },
          "0531": {
              "S6": 2,"S5": 2, "S4": 2,
              "S3": 4,"S2": 4, "S1": 4,
              "A": 4, "B": 5, "C": 4, "D": 3,
          },













      }
    }
  },
  components: {
    Calendar
  },
  async beforeCreate() {
    Hub.listen("auth", async (data) => {
      switch (data.payload.event) {
        case "signIn": { 
          const cognitoUser = await Auth.currentAuthenticatedUser();
          var idToken = cognitoUser.signInUserSession.idToken.jwtToken 
          console.log(`signed in ... ${cognitoUser.username}`);
          localStorage.setItem('idToken', idToken)
          localStorage.setItem('name', cognitoUser.attributes.name)
          this.idToken = idToken
          //this.$router.replace({ path: '/mypage' })

          break;
        }
        default:
          break;
      }
    });
  },
  methods: {
    is_authenticated() {
      var idToken = localStorage.getItem("idToken")
      if(idToken != null) {
        return true
      } else {
        return false
      }
    },
    async signout() {
      try {
        await Auth.signOut();
        this.$router.push("/login");
      } catch (e) {
        console.log(e);
      }
    },
    formatNum(n) {
      return Math.floor(n / 1000)
    },
    getEventLast(i, r) {
      var m = moment().format('MMDD')
      if(i == 1) {
        m = moment().add(1, 'days').format('MMDD')
      } else if(i==2) {
        m = moment().add(2, 'days').format('MMDD')
      }

      if(r != "E1") {
        if(this.eventLast[m] == undefined) {
          return 0
        } else {
          if(r.indexOf('A') != -1) {
            return this.eventLast[m]["A"]
          } else if(r.indexOf('B') != -1) {
            return this.eventLast[m]["B"]
          } else if(r.indexOf('C') != -1) {
            return this.eventLast[m]["C"]
          } else if(r.indexOf('D') != -1) {
            return this.eventLast[m]["D"]
          } else {
            return this.eventLast[m][r]
          }
        }
      } else {
        return 0
      }
    },
    cardTitle(i) {
      if(i == 0) {
        var m = moment().format('DD')
        return "今日(" + m + "日)のメーター目安(" + this.rank + " " + this.e_id.toString() + "時締め)"
      } else if(i == 1){
        var m2 = moment().add(1, 'days').format('DD')
        return "明日(" + m2 + "日)のメーター目安(" + this.rank + " " + this.e_id.toString() + "時締め)"
      } else if(i == 2){
        var m3 = moment().add(2, 'days').format('DD')
        return "明後日(" + m3 + "日)のメーター目安(" + this.rank + " " + this.e_id.toString() + "時締め)"
      }
    },
    onChangeRank() {
      this.forecastMeterBorder(this.rank, this.e_id)
    },
    isNotMember() {
      if(this.mode == 'not_member') {
        return "bottom_footer"
      }
    },
    isActive(i) {
      return (i == 0)? "active" : ""
    },
    forecastMeterBorder(rank, e_id) {
      axios
        .get("https://line.pinolive-analysis.com/api/v1/meter_border?rank=" + rank + "&e_id=" + e_id, {headers: {'Authorization': this.idToken}})
        .then((res) => {
           this.forecast = res.data.result
           this.days = res.data.days
        }).catch(() => {
          this.signout()
        });
    }
  },
  mounted() {
    axios
      .get("https://line.pinolive-analysis.com/api/v1/user/index", {headers: {'Authorization': this.idToken}})
      .then((res) => {
         var user = res.data
         this.rank = res.data.current_rank
         this.e_id = res.data.eventtime_id

         if(user.user_type == null || user.user_type == "") {
           this.mode = "not_member"
         } else {
           this.mode = "member"
           this.forecastMeterBorder(this.rank, this.e_id)
         }
      }).catch(() => {
        this.signout()
      });
  }
}
</script>

<style scoped lang="scss">
.home {
  background: #FEFEFE;
}
.weekly_card {
  border: 2px solid #F9A599;
  border-radius: 8px;
  box-shadow: 3px 3px 2px 1px rgba(0, 0, 0, .2);

  .card_title {
    color: #F9A599;
    font-size: 14px;
    font-weight: 600;
  }
  p.plus2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    span.index {
      font-size: 16px;
      font-weight: 600;
    }
    span.value {
      font-size: 20px;
      font-weight: 600;
    }
  }
}
.today_label {
  font-size: 18px;
}
.pl-0 {
  padding-left: 0px !important;
}
.small-select {
  font-size: 1rem;
}
.carousel-control-icon {
  color: #333;
  i {
    color: #333;
    font-size: 32px;
  }
}

h2 {
  font-size: 15px;
  font-weight: 600;
}
.bottom_footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.footer {
  background: #353330;
  padding: 25px 10px;
  padding-bottom: 0px;
  text-align: left;
  color: white;
  width: 100%;

  p, a {
    font-size: 12px;
    margin-top: 3px;
    margin-bottom: 5px;
    text-decoration: none;
    color: white;
  }
}
.text-left {
  text-align: left; 
}
.w100 {
  width: 100%;
}

</style>
